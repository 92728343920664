import React, { useState } from 'react';
import { InformationCircleIcon, PencilIcon } from '@heroicons/react/outline';
import { gql } from '@apollo/client/core';
import { getFormattedValue } from '../../utils/string-utils';
import { DatePicker, Table, Tag, Tooltip } from 'antd';
import { PurchaseOrderStatus } from '../../enums/spanish.enum';
import { useMutation, useQuery } from '@apollo/client';
import {
  reviewOrders,
  reviewOrders_adminOrders_nodes_AdminOrder,
  reviewOrdersVariables,
} from '../../__api__/reviewOrders';
import { useMe } from '../../hooks/use-me';
import { FacType, OrderStatus, Role } from '../../__api__/globalTypes';
import { Helmet } from 'react-helmet-async';
import { Titles } from '../../enums/titles.enum';
import { Labels } from '../../enums/labels.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Modal } from '../../components/modal';
import {
  SkeletonTable,
  SkeletonTableColumnsType,
} from '../../components/skeleton-table';
import { roundWithTwoDecimals } from '../../utils/numeric-utils';
import { parseToShow, filterObject, keysToFilterDetail, keysToFilterResult, Field } from '../../utils/display-utils';
import { JSONDetail } from '../../components/jsonDetails';
import { Review } from './review-order-dialogues/review-order-dialogue';
import {
  updateOrderMutation,
  updateOrderMutationVariables,
} from '../../__api__/updateOrderMutation';
import { UPDATE_ORDER_MUTATION } from '../../common-mutations/common-mutations';
import { UpdateResultPurchaseOrder } from './review-order-dialogues/update-result-order-dialogue';

const { RangePicker } = DatePicker;

const ORDER_QUERY = gql`
  query reviewOrders($input: PurchaseOrderSearcherInput!) {
    adminOrders(input: $input) {
      meta {
        nodeCount
        nodesPerPage
        pageCount
        pageCurrent
      }
      nodes {
        __typename
        ... on AdminOrder {
          id
          product {
            name
            saleCost
            description
          }
          customer {
            name
            lastName
            identificationNumber
            cellPhone
            distributorEmail
          }
          adminDetail
          comment
          createdAt
          updatedAt
          status
          total
          precioVenta
          productionCost
          balanceNuevo
          reviewerUserId
          reviewerEmail
          result
          commission
          alternateName
          alternateDesc
          factNames
          facLastnames
          facAddress
          facID
          facIDType
        }
      }
    }
  }
`;

const DEFAULT_PAGE_SIZE = 10;

export const ReviewOrders = () => {
  const { data: user } = useMe();

  const [pageCurrent, setPageCurrent] = useState(1);
  const [nodesPerPage, setNodesPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);
  const [isModalUpdateResultVisible, setIsModalUpdateResultVisible] = useState(false);
  const [isModalReviewVisible, setIsModalReviewVisible] = useState(false);
  const [isModalDetailVisible, setIsModalDetailVisible] = useState(false);
  const [displayItem, setDisplayItem] = useState<any>(null);
  const [status, setStatus] = useState<OrderStatus[] | null>(null);
  const [
    order,
    setOrder,
  ] = useState<reviewOrders_adminOrders_nodes_AdminOrder | null>(null);

  const { data, loading, refetch } = useQuery<
    reviewOrders,
    reviewOrdersVariables
  >(ORDER_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        pageCurrent,
        nodesPerPage,
        where: {
          customerId: user?.me.customer?.id,
          dateFrom,
          dateTo,
          status,
        },
      },
    },
  });

  
  const onCompleted = async (data: updateOrderMutation) => {
    const { updatePurchaseOrder: orderId } = data;
    if (orderId) {
      refetch();
    }
  };

  const [
    updateOrderMutation,
    { loading : loadingMutation },
  ] = useMutation<updateOrderMutation, updateOrderMutationVariables>(
    UPDATE_ORDER_MUTATION,
    { onCompleted },
  );

  const updateOrder = async (
    id: string,
    status: OrderStatus) => {
    if (!loadingMutation) {
      try {
        await updateOrderMutation({
          variables: {
            input: {
              userId: user?.me.id || '',
              id,
              status
            },
          },
        });
      } catch (error) { }
    }
  };

  const getLabelsForRole = () => {
    if (user?.me.role === Role.ADMIN) {
      return {
        id: "ID",
        product: "Producto",
        customer: "Cliente",
        comment: "Comentario",
        createdAt: "Fecha del Pedido",
        updatedAt: "Fecha de Revision",
        status: "Estado",
        adminDetail: "Detalles",
        result: "Resultado",
        total: "Total",
        comission: "Comisión",
        productionCost: "Costo de Producción",
        precioVenta: "PVP",
        alternateName: "Nombre Real del Producto",
        alternateDesc: "Descripcion Real del Producto",
        factNames: "Nombre Del Cliente",
        facLastnames: "Apellido del Cliente",
        facAddress: "Dirección del Cliente",
        facID: "Numero de Identificación del Cliente",
        facIDType: "Typo de Identificación del Cliente",
        reviewerUserId: "ID de Usuario de Revision",
        reviewerEmail: "Mail de Usuario de Revision",
      };
    }
    else if (user?.me.role === Role.DISTRIBUTOR) {
      return {
        id: "ID",
        product: "Producto",
        customer: "Cliente",
        comment: "Comentario",
        createdAt: "Fecha del Pedido",
        status: "Estado",
        adminDetail: "Detalles",
        result: "Resultado",
        total: "Total",
        comission: "Comisión",
        productionCost: "Costo de Producción",
        alternateName: "Nombre Real del Producto",
        alternateDesc: "Descripcion Real del Producto",
        factNames: "Nombre Del Cliente",
        facLastnames: "Apellido del Cliente",
        facAddress: "Dirección del Cliente",
        facID: "Numero de Identificación del Cliente",
        facIDType: "Typo de Identificación del Cliente",
      };
    }
    else if (user?.me.role === Role.OPERATOR) {
      return {
        id: "ID",
        product: "Producto",
        customer: "Cliente",
        comment: "Comentario",
        createdAt: "Fecha del Pedido",
        status: "Estado",
        adminDetail: "Detalles",
        result: "Resultado",
        total: "Total",
        productionCost: "Costo de Producción",
        precioVenta: "PVP",
        alternateName: "Nombre Real del Producto",
        alternateDesc: "Descripcion Real del Producto",
        factNames: "Nombre Del Cliente",
        facLastnames: "Apellido del Cliente",
        facAddress: "Dirección del Cliente",
        facID: "Numero de Identificación del Cliente",
        facIDType: "Typo de Identificación del Cliente",
      };
    }

  }

  const purchaseOrders = data?.adminOrders.nodes as
    | reviewOrders_adminOrders_nodes_AdminOrder[]
    | null;

  const baseColumns = [
    {
      title: 'Fecha de creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const adminColumns = [
    {
      title: 'ID Cliente',
      dataIndex: 'identificationNumber',
      key: 'identificationNumber',
    },
    {
      title: 'Producto',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Valor de compra',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Costo de producción',
      dataIndex: 'productionCost',
      key: 'productionCost',
    },
    {
      title: 'Ganancia',
      dataIndex: 'gain',
      key: 'gain',
    },
    {
      title: 'Comisión',
      dataIndex: 'commission',
      key: 'commission',
    },
    ...baseColumns,
  ];

  const operatorColumns = [
    {
      title: 'ID Cliente',
      dataIndex: 'identificationNumber',
      key: 'identificationNumber',
    },
    {
      title: 'Producto',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Valor de compra',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Costo de producción',
      dataIndex: 'productionCost',
      key: 'productionCost',
    },
    ...baseColumns,
  ];

  const distributorColumns = [
    {
      title: 'ID Cliente',
      dataIndex: 'identificationNumber',
      key: 'identificationNumber',
    },
    {
      title: 'Nombre Cliente',
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: 'Producto',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Valor de compra',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Comisión',
      dataIndex: 'commission',
      key: 'commission',
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  const columns =
    user?.me.role === Role.ADMIN
      ? adminColumns
      : user?.me.role === Role.DISTRIBUTOR
        ? distributorColumns
        : operatorColumns;

  const datasource = purchaseOrders?.map((item) => ({
    key: item.id,
    identificationNumber: item.customer.identificationNumber,
    customerName: `${item.customer.name} ${item.customer.lastName}`,
    product: item.alternateName ? item.alternateName : item.product.name,
    productionCost: `$ ${getFormattedValue(item.productionCost || 0)}`,
    total: `$ ${getFormattedValue(item.total || 0)}`,
    gain: `$ ${getFormattedValue(
      roundWithTwoDecimals(item.total - item.productionCost || 0),
    )}`,
    commission: `$ ${getFormattedValue(item.commission || 0)}`,
    createdAt: item.createdAt,
    distributorEmail: item.customer.distributorEmail,
    detail: item.adminDetail ? (
      <ul>
        {filterObject(keysToFilterDetail, item.adminDetail.fields).map((field: Field) => (
          <li key={field.name}>
            <span className="font-semibold">
              {field.name}
              {': '}
            </span>
            <span>{parseToShow(field.value)}</span>
          </li>
        ))}
      </ul>
    ) : (
      ''
    ),
    result: item.result ? (
      <ul>
        {filterObject(keysToFilterResult, item.result.fields).map((field: Field) => (
          <li key={field.name}>
            <span className="font-semibold">
              {field.name}
              {': '}
            </span>
            <span>{parseToShow(field.value)}</span>
          </li>
        ))}
      </ul>
    ) : (
      ''
    ),
    facdata: (
      <ul>
        <li>
          <span className="font-semibold">
            {'Tipo de Documento'}
            {': '}
          </span>
          <span>{item.facIDType}</span>
        </li>
        {item.facIDType !== FacType.CONSUMIDORFINAL && (
          <li>
            <span className="font-semibold">
              {'Numero de Documento'}
              {': '}
            </span>
            <span>{item.facID}</span>
          </li>)}
        {item.facIDType !== FacType.CONSUMIDORFINAL && (
          <li>
            <span className="font-semibold">
              {'Nombres'}
              {': '}
            </span>
            <span>{item.factNames}</span>
          </li>)}
        {item.facIDType !== FacType.CONSUMIDORFINAL && (
          <li>
            <span className="font-semibold">
              {'Apellidos'}
              {': '}
            </span>
            <span>{item.facLastnames}</span>
          </li>)}
        {item.facIDType !== FacType.CONSUMIDORFINAL && (
          <li>
            <span className="font-semibold">
              {'Dirección'}
              {': '}
            </span>
            <span>{item.facAddress}</span>
          </li>)}
      </ul>
    ),
    comment: item.comment,
    status: (
      <Tag
        color={
          item.status === 'ACCEPTED' || item.status === 'DEVOLUTION_ACCEPTED'
            ? 'geekblue'
            : item.status === 'PENDING' || item.status === 'DEVOLUTION_REQUESTED'
              ? 'orange'
              : item.status === 'REJECTED' || item.status === 'DEVOLUTION_REJECTED'
                ? 'error'
                : 'cyan'
        }
      >
        {PurchaseOrderStatus[item.status]}
      </Tag>
    ),
    action: (
      <div className="flex items-center justify-start">
        <div
          onClick={() => {
            setDisplayItem(item);
            setIsModalDetailVisible(true);
          }}
          className="flex-shrink-0 mr-2"
        >
          <Tooltip title={'ver detalle'}>
            <InformationCircleIcon
              className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
              aria-hidden="true"
            />
          </Tooltip>
        </div>
        {(moment().diff(moment(item.updatedAt), 'hours') < 1 && OrderStatus.ACCEPTED == item.status && (user?.me.role === Role.ADMIN || user?.me.id == item.reviewerUserId)) && (
          <div
          onClick={() => {
            setOrder(item);
            setIsModalUpdateResultVisible(true);
          }}
          className="flex-shrink-0 mr-2"
        >
          <Tooltip title={'editar respuesta'}>
            <PencilIcon
              className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
              aria-hidden="true"
            />
          </Tooltip>
        </div>
        )}
        {[OrderStatus.REVISION, OrderStatus.PENDING].includes(item.status) && (
          <button
            onClick={async () => {
              await updateOrder(item.id, OrderStatus.REVISION);
              setOrder(item);
              setIsModalReviewVisible(true);
            }}
            type="button"
            className={`flex justify-center w-full py-1 text-sm font-medium text-white border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${(!item.reviewerUserId || item.reviewerUserId === user?.me.id || OrderStatus.PENDING === item.status)
                ? 'bg-indigo-600 hover:opacity-90'
                : 'bg-indigo-300 pointer-events-none'
              }`}
          >
            <span>Revisar</span>
          </button>
        )}
        <div className="divider w-2" />
        {(OrderStatus.REVISION === item.status && user?.me.role === Role.ADMIN) && (
          <button
            onClick={async () => {
              await updateOrder(item.id, OrderStatus.PENDING);
              refetch();
            }}
            type="button"
            className={`flex justify-center w-full py-1 text-sm font-medium text-white border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-indigo-600 hover:opacity-90'
      }`}
          >
            <span>Liberar</span>
          </button>
        )}
      </div>),
  }));

  const onChange = (values: any) => {
    if (!values) {
      setDateFrom(null);
      setDateTo(null);
      return;
    }
    const dateFrom = values[0].format();
    const dateTo = values[1].format();

    setDateFrom(dateFrom);
    setDateTo(dateTo);
  };

  return (
    <div>
      <Modal
        title="Editar Respuesta"
        visible={isModalUpdateResultVisible}
        onOk={async () => {
          setIsModalUpdateResultVisible(false);
          await refetch();
        }}
        onCancel={async () => {
          setIsModalUpdateResultVisible(false);
          await refetch();
        }}
        child={
          <UpdateResultPurchaseOrder
            order={order}
            onOk={async () => {await refetch();
              if (isModalUpdateResultVisible) {
                setIsModalUpdateResultVisible(false);
              }}}
          />
        }
      />
      <Modal
        title="Detalles del pedido"
        visible={isModalDetailVisible}
        onOk={async () => {
          setIsModalDetailVisible(false);
        }}
        onCancel={async () => {
          setIsModalDetailVisible(false);
        }}
        child={<JSONDetail json={displayItem}
          labels={getLabelsForRole()} />}
      />
      <Modal
        title="Revisión del pedido"
        visible={isModalReviewVisible}
        onOk={async () => {
          setIsModalReviewVisible(false);
          await refetch();
        }}
        onCancel={async () => {
          setIsModalReviewVisible(false);
          await refetch();
        }}
        child={
          <Review
            order={order}
            onOk={async () => {await refetch();
              if (isModalReviewVisible) {
                setIsModalReviewVisible(false);
              }}}
          />
        }
      />
      <Helmet>
        <title>{Titles.ORDERS}</title>
      </Helmet>
      <div className="px-4 py-6">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900 leading-6">
              {Labels.MENU_ORDERS}
            </h3>
          </div>
          <div className="mt-5 mb-5 md:mt-0 md:col-span-2">
            <form className="flex flex-col items-center w-full py-8 bg-white shadow sm:rounded-3xl sm:px-4">
              <div className="mb-4 mr-auto">
                {Labels.FILTERS}
                <FontAwesomeIcon icon={faFilter} className="ml-3 text-xl" />
              </div>
              <div className="max-w-xl grid grid-cols-1 gap-5">
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_DATES}</span>
                  </label>
                  <RangePicker
                    showTime={true}
                    placeholder={['Fecha desde', 'Fecha hasta']}
                    className="w-full rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={onChange}
                    disabledDate={(current) => moment().isBefore(current)}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_STATUS}</span>
                  </label>
                  <select
                    name="status"
                    onChange={(event) => {
                      const value = event.target.value;
                      setStatus(value ? ([value] as OrderStatus[]) : null);
                    }}
                    className="w-full select"
                  >
                    <option value="">Todos</option>
                    {Object.keys(OrderStatus).map((state) => (
                      <option key={state} value={state}>
                        {PurchaseOrderStatus[state as any]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </form>
          </div>
          <SkeletonTable
            active={true}
            loading={loading}
            columns={columns as SkeletonTableColumnsType[]}
          >
            <Table
              dataSource={datasource}
              columns={columns}
              pagination={{
                defaultPageSize: DEFAULT_PAGE_SIZE,
                pageSize: nodesPerPage,
                current: pageCurrent,
                total: data?.adminOrders.meta.nodeCount,
                responsive: true,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '100'],
                onShowSizeChange: (current, size) => setNodesPerPage(size),
                onChange: (page) => setPageCurrent(page),
              }}
            />
          </SkeletonTable>
        </div>
      </div>
    </div>
  );
};
