import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { FormError } from '../components/form-error';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';
import logo from '../images/logo2.svg';
import { Button } from '../components/button';
import { FormErrorMessages } from '../enums/form-error-messages.enum';
import { Labels } from '../enums/labels.enum';
import { Titles } from '../enums/titles.enum';
import {
  signInMutation,
  signInMutationVariables,
} from '../__api__/signInMutation';
import { authUserId, isLoggedInVar } from '../apollo';
import {
  EMAIL_REGEX,
  LOCALSTORAGE_USER_ID,
  PASSWORD_VALID_CHARACTERS_REGEX,
  STRONG_PASSWORD_REGEX,
} from '../constants';
import { Link, useHistory } from 'react-router-dom';
import { Exceptions } from '../enums/exceptions.enum';

const SIGN_IN_MUTATION = gql`
  mutation signInMutation($input: SignInInput!) {
    signIn(input: $input)
  }
`;

interface ILoginForm {
  email: string;
  password: string;
}

export const Login = () => {
  const history = useHistory();
  const {
    register,
    getValues,
    errors,
    handleSubmit,
    formState,
  } = useForm<ILoginForm>({
    mode: 'onChange',
  });
  const onCompleted = (data: signInMutation) => {
    const { signIn: userId } = data;
    if (userId) {
      localStorage.setItem(LOCALSTORAGE_USER_ID, userId);
      authUserId(userId);
      isLoggedInVar(true);
      history.push('/');
    }
  };
  const [signInMutation, { loading, error }] = useMutation<
    signInMutation,
    signInMutationVariables
  >(SIGN_IN_MUTATION, {
    onCompleted,
  });
  const onSubmit = async () => {
    if (!loading) {
      try {
        const { email, password } = getValues();
        await signInMutation({
          variables: {
            input: {
              email,
              password,
            },
          },
        });
      } catch (error) {}
    }
  };

  return (
    <div className="flex flex-col justify-center px-2 py-12 sm:px-6 lg:px-8">
      <Helmet>
        <title>{Titles.LOGIN_PAGE}</title>
      </Helmet>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Link to="/">
          <img className="w-auto h-40 mx-auto" src={logo} alt="Workflow" />
        </Link>
        <h2 className="mt-1 text-2xl font-extrabold text-center text-gray-900">
          {Labels.SIGN_IN}
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor="email" className="label">
                {Labels.EMAIL}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_EMAIL,
                    pattern: {
                      value: EMAIL_REGEX,
                      message: FormErrorMessages.VALID_EMAIL,
                    },
                  })}
                  name="email"
                  type="email"
                  className="input"
                />
                {errors.email?.message && (
                  <FormError errorMessage={errors.email?.message} />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="password" className="label">
                {Labels.PASSWORD}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_PASSWORD,
                    minLength: 8,
                    maxLength: 50,
                    validate: {
                      isValidCharacters: (password) =>
                        PASSWORD_VALID_CHARACTERS_REGEX.test(password) ||
                        FormErrorMessages.PASSWORD_ERROR_MESSAGE,
                      strongPassword: (password) =>
                        STRONG_PASSWORD_REGEX.test(password) ||
                        FormErrorMessages.PASSWORD_ERROR_MESSAGE,
                    },
                  })}
                  name="password"
                  type="password"
                  className="input"
                />
                {errors.password?.message && (
                  <FormError errorMessage={errors.password?.message} />
                )}
                {(errors.password?.type === 'minLength' ||
                  errors.password?.type === 'maxLength') && (
                  <FormError
                    errorMessage={FormErrorMessages.PASSWORD_ERROR_MESSAGE}
                  />
                )}
              </div>
            </div>

            <div className="flex items-end justify-between">
              <div className="text-sm text-right">
                <Link
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                  to="/recover-password"
                >
                  {Labels.FORGOT_PASSWORD}
                </Link>
              </div>
            </div>

            

            <div className="flex items-end justify-between">
              <div className="text-sm text-right">
                <Link
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                  to="/register-new-user"
                >
                  {Labels.REGISTER_NEW_USER}
                </Link>
              </div>
            </div>

            <div>
              <Button
                canClick={formState.isValid}
                loading={loading}
                actionText={Labels.ENTER}
              />
              {error && (
                <FormError errorMessage={Exceptions[error.message as any]} />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
