import React, { Fragment, useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Labels } from '../enums/labels.enum';
import { Link } from 'react-router-dom';
import logo from '../images/logo2.svg';
import ws from '../images/ws-icon.png';
import cover from '../images/cover.jpg';
import { FooterLogout } from '../components/footer-logout';
import { Titles } from '../enums/titles.enum';
import { Helmet } from 'react-helmet-async';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaAngleUp } from 'react-icons/fa';

//-----Videojuegos ------
import netflix from '../images/netflix-icon.png';
import disney from '../images/disney-icon.png';
import prime from '../images/prime-icon.png';
import iptv from '../images/IPTV-icon.png';
import spotify from '../images/spotify-icon.png';
import freeFire from '../images/free-fire-icon.jpeg';
import callOfDuty from '../images/call-of-duty-icon.png';
import roblox from '../images/roblox-icon.png';
import clashRoyale from '../images/clash-royale-icon.png';
import pubgmobile from '../images/pubgmobile.png';
import brawstar from '../images/Brawl-star.avif';
import parchis from '../images/parchis.png';

//-----Imagenes carrosuel ------
import slider1 from '../images/slider/slider-1.png';
import slider2 from '../images/slider/slider-2.png';
import slider3 from '../images/slider/slider-3.png';
import slider4 from '../images/slider/slider-4.png';
import slider5 from '../images/slider/slider-5.png';
import slider6 from '../images/slider/slider-6.png';

/*Icons recargas celulares*/
import claro from '../images/Claro.jpeg';
import movistar from '../images/Movistar.png';
import tuenti from '../images/Tuenti.png';
import cnt from '../images/CNT.png';
import cellvoz from '../images/Cellvoz.png';
import virgin from '../images/Virgin.png';
import une from '../images/Une.jpeg';
import movilExito from '../images/Movil-exito.jpeg';
import luzCuba from '../images/Luz-cuba.jpeg';
import flashMobile from '../images/Flash-mobile.png';
import digitel from '../images/Gigitel.jpeg';
import cubacell from '../images/Cubacell.jpeg';
import clashOfClans from '../images/clashOfClans.avif';

/*Icons servicio basicos*/
import agua from '../images/Agua.png';
import luz from '../images/Luz.png';
import alicuotas from '../images/Alicuotas.jpeg';
import sri from '../images/SRI.png';
import pagos from '../images/Pagos.png';
import consejoJudicactura from '../images/Consejo judicatura.png';
import creditos from '../images/Credito.png';
import depositos from '../images/Depositos.png';
import iess from '../images/IESS.png';
import municipios from '../images/Municipios.png';
import ATM from '../images/ATM.png';
import AMT from '../images/AMT.jpg';
import catalogos from '../images/catalogos.png';
import akiMovil from '../images/akiMovil.png';
import Maxiplus from '../images/Maxiplus.png';
import direcTv from '../images/direcTv.png';
import telefono from '../images/telefono.png';
import internet from '../images/internet.png';
import pronosticos from '../images/pronosticos.png';
import tarjetas from '../images/tarjetas.jpeg';

/*Icons gifts cards*/
import nintento from '../images/nintendo-icon.jpeg';
import mcafee from '../images/Pines-mcafee.png';
import pinFortnite from '../images/Pines-fortnite.png';
import razerGold from '../images/Pines-razer-gold.png';
import pinLegendsBangBang from '../images/Pines-legends-bang-bang.png';
import pinXbox from '../images/Pines-xbox.png';
import pinSteam from '../images/Pines-steam.png';
import pinPlayStation from '../images/Pines-play-station.png';
import itunes from '../images/itunes.png';
import amazon from '../images/amazon.png';


/*Icons streaming*/
import hbomax from '../images/hbomax.png';
import magistv from '../images/magistv.png';
import canva from '../images/canva.jpeg';
import crunchyrroll from '../images/crunchyrroll.png';
import youtube from '../images/youtube-icon.png';
import paramount from '../images/paramount.png';
import plex from '../images/plex.jpeg';
import ecdf from '../images/ecdf.png';

/*Icons Licencias*/
import discord from '../images/discord.png';
import nordvpn from '../images/nordvpn.png';
import eset from '../images/eset.png';

const navigation = [
  { name: 'Que es Gamerecar ?', href: '#que_es_gamerecar' },
  { name: 'Nuestros Productos', href: '#productos' },
  { name: 'AFÍLIATE GRATIS', href: '#afiliacion' },
  { name: 'Comisiones', href: '#comisiones' },
  { name: 'Contáctanos', href: '#contactanos' },
  { name: 'Api Rest', href: '#api' },
];

const recargas = [
  {
    name: 'Recargas claro',
    description: 'Disponible recargas de saldo y paquetes.',
    image: claro,
  },
  {
    name: 'Recargas movistar',
    description: 'Disponible recargas de saldo y paquetes.',
    image: movistar,
  },
  {
    name: 'Recargas tuenti',
    description: 'Disponible recargas de saldo y paquetes.',
    image: tuenti,
  },
  {
    name: 'Recargas CNT',
    description: 'Disponible recargas de saldo y paquetes.',
    image: cnt,
  },
  {
    name: 'Recargas Aki Movil',
    description: 'Disponible recargas de saldo y paquetes.',
    image: akiMovil,
  },
  {
    name: 'Recargas Maxiplus',
    description: 'Disponible recargas de saldo y paquetes.',
    image: Maxiplus,
  },
  {
    name: 'Recargas direcTv',
    description: 'Disponible recargas de saldo',
    image: direcTv,
  },

];



const serviciosbasicos = [
  {
    name: 'Agua',
    description: 'Pagos de agua de todas la provincias. EMAAP - QUITO, INTERAGUA GUAYAS, AGUAPEN SANTA ELENA, AGUAS DE CHONE, AGUAPEN SANTA ELENA, AGUAS MACHALA, AMAGUA - SAMBORONDON, EMAPA CAYAMBE DAULE LAGO AGRIO -AMBATO IBARRA, EMAPSE ESMERALDAS,  EMAPAG-GUARANDA,  EMSABA BABAHOYO, EPAM MANTA, EPMAPA SANTO DOMINGO, AGUA - LOJA, LATACUNGA, OTAVALO, RIOBAMBA, TULCAN, y muchos más',
    image: agua,
  },
  {
    name: 'Luz',
    description: 'Pagos de luz de todas las provincias. EEQ QUITO, CNEL - GUAYAS LOS RIOS, CENTROSUR AZUAY, CNEL - BOLIVAR, CNEL - EL ORO, CNEL - ESMERALDAS, CNEL - MANABI, CNEL - MILAGRO, CNEL - PLAYAS, CNEL - SANTA ELENA,  CNEL - STO. DOMINGO, EEASA TUNGURAHUA, EERSA CHIMBORAZO, EERSSA LOJA, ELECTRICA GALÁPAGOS, EMELNORTE (IMBABURA-CARCHI-CAYAMBE), y muchos más',
    image: luz,
  },
  {
    name: 'Teléfono',
    description: 'Pagos de teléfonos fijos CNT - INTERNET, CNT - TELEFONIA FIJA, CNT - TV PAGADA',
    image: telefono,
  },
  {
    name: 'Depósitos Bancos y Cooperativas',
    description:
      'PRODUBANCO, BANCO DE LOJA,BANECUADOR, BANCO COMERCIAL MANABI , BANCO FINCA EN LINEA, GENERAL RUMIÑAHUI, BANCO PROCREDIT S.A., COOPERATIVA ALIANZA DEL VALLE, ANDALUCIA, ATUNTAQUI, CCP - CONSTRUCCION COMERCIO PRODUCCION, COOPROGRESO, MUTUALISTA PICHINCHA, TULCAN y muchos más',
    image: depositos,
  },
  {
    name: 'ATM',
    description: 'Pago de ordenes de ATM. GUAYAQUIL - PAGO DE CITACIONES, PAGO DE TARIFAS POR NÚMERO DE TRAMITE,  RENOVACIÓN DE PERMISO CIRCULACIÓN VEHICULAR',
    image: ATM,
  },
  {
    name: 'Catálogos',
    description: 'Pago de catálogos. AVON, AZZORTI, BELCORP, HERBALIFE, L-EUDINE, LEONISA, LILE, MIO, NIVI, OMNILIFE, ORIFLAME, RELEVANCE, REVOLUSS,  RIHE -MARTTELOCORP, YANBAL, ZERMAT y muchos más',
    image: catalogos,
  },
  {
    name: 'Pago Claro y Movistar',
    description: 'PLAN POSTPAGO,  PLANES DE CARTERA VENCIDA, PLANES TV SATELITAL, TELEFONIA FIJA, LAN POSTPAGO - VALOR MAYOR A 10 USD, PLAN POSTPAGO PA',
    image: claro,
  },
  {
    name: 'Consejo judicactura',
    description: 'Pago de ordenes PENSION ALIMENTICIA EMPRESAS,  PENSION ALIMENTICIA PERSONAS',
    image: consejoJudicactura,
  },
  {
    name: 'Pago créditos',
    description: 'ALMACENES ESPAÑA, JAPÓN, JAPÓN MOTOS, ORVE HOGAR, ARTEFACTA, AUTOMOTORES Y ANEXOS AYASA,  BANCO CODESARROLLO, DMIRO, FINCA, PROCREDIT, SOLIDARIO, ARTEFACTA, COGESCORP, COLLSERV, CREDITO - CEBI, CHEVYPLAN, CHIMASA, COBRAMAS, CREDICEL, NOVACREDIT , D-PRATI, CREDITOS ECONOMICOS, ETAFASHION, MARCIMEX, PYCCA, TODOHOGAR y muchos más',
    image: creditos,
  },
  {
    name: 'IESS',
    description: 'Pago de ordenes del IESS. VOLUNTARIO, IESS',
    image: iess,
  },
  {
    name: 'Internet y TV Pagada',
    description: 'NETLIFE, OPTICOM, PUNTONET,ALFANET, CABLE FUTURO, CABLE HOGAR, CABLESPEED, CNT, COPERCONET, DIRECTV, ECUANET , FASTNETT, FIBER STORE, FIBRAMAX , MASNET , MAXITEL , SPEEDYCOM, TERRANET, TVCABLE, UNIVISA, VELOCITY, y muchos más ',
    image: internet,
  },
  {
    name: 'Matriculas y Pensiones',
    description: 'CAP RAFAEL MORAN VALVERDE, CENEST HARVARD, CENTRO EDUCATIVO LETORT,  COLEGIO ALMIRANTE NELSON, COLEGIO GEORGE WASHINGTON,  EFI CORDILLERA, IST BOLIVARIANO, LICEO DE LOS ANDES, UDA UNIVERSIDAD DEL AZUAY, UNIDAD EDUCATIVA GUAYAS, UNIV IBEROAMERICANA DEL ECUADOR, UNIVERSIDAD DE LOS ANDES (UNIANDES), UNIVERSIDAD INDOAMERICA,  UTPL  y muchos más',
    image: alicuotas,
  },
  {
    name: 'Municipios',
    description: 'Pago de ordenes y predios de todos los Municipios de Ecuador. QUITO, CUENCA, AMBATO, AMBATO, BAÑOS, CAÑAR, CHONE, CAYAMBE, GUARANDA, GUAYAQUIL, IBARRA, MILAGRO, MORONA, PORTOVIEJO, RUMIÑAHUI, STO. DOMINGO, CUENCA, MANTA, MEJIA  y muchos más',
    image: municipios,
  },
  {
    name: 'Pagos de Empresas Varias',
    description: 'ARCSA, SERVICOBRANZAS, ELECTROEXITO, DURAGAS, EQPAY, FIDEVAL, FIDUCIA, HAPPYCEL, HINO, HUNTER, PAGOSEGURO, PAYCASH, REGISTRO CIVIL, REGISTRO MERCANTIL, REGISTRO PROPIEDAD, ROLAND, SWEADEN, TIPTI, TREBALIA, YIGA 5, PAGO EFECTIVO, y muchos más',
    image: pagos,
  },
  {
    name: 'Pronósticos Deportivos',
    description: 'BET593, BETCRIS, ECUABET, TREBALIA',
    image: pronosticos,
  },
  {
    name: 'Pagos SRI',
    description: 'IMPUESTOS CEP, MATRICULACION - TRANSFERENCIA DOMINIO, MATRICULACION AJUSTE, MATRICULACION VEHICULAR, RISE ',
    image: sri,
  },
  {
    name: 'Pago de Tarjetas de Crédito',
    description:
      'BANCO COMERCIAL MANABI, BANCO DE LOJA, BCO SOLIDARIO - ALÍA, COOPERATIVA TULCAN, MASTERCARD PRODUBANCO, PACIFICARD VISA y MC, VISA BGR, VISA PRODUBANCO ',
    image: tarjetas,
  },
  {
    name: 'Tránsito',
    description: 'ANT - CITACIONES, ANT - PAGO SERVICIOS, CTE - COMISION DE TRANSITO ECUADOR, EMOV, ATM - CITACIONES, GAD PICHINCHA FONDO MEJORAMIENTO VIAL,  GADP PICHINCHA PEAJE, LOJA, MOVILIDAD, PEAJE RUMIÑAHUI, REVISION VEHICULAR QUITO, TUNEL GUAYASAMIN y muchos más ',
    image: AMT,
  },

];


const entretenimiento = [
  {
    name: 'Netflix',
    description: 'Cuentas completas y perfiles originales.',
    image: netflix,
  },
  {
    name: 'Combos Disney + Start Plus',
    description: 'Combos de 1 mes originales y renovaciones',
    image: disney,
  },
  {
    name: 'Amazon Prime Video',
    description:
      'Amazon 1 mes, 3 meses, 6 meses renovables originales',
    image: prime,
  },
  {
    name: 'HBO MAX',
    description: 'HBO Max 1 mes original',
    image: hbomax,
  },
  {
    name: 'Magistv',
    description: 'Magistv de 1 a 12 meses de 3 dispositivos',
    image: magistv,
  },
  {
    name: 'IPTV',
    description: 'Iptv de 1 a 12 meses de 3 dispositivos',
    image: iptv,
  },
  {
    name: 'Spotify',
    description:
      'Spotify original de 1 a 12 meses',
    image: spotify,
  },
  {
    name: 'Canva Pro',
    description:
      'Canva Pro de 1 mes y 12 meses Originales',
    image: canva,
  },
  {
    name: 'Crunchyroll',
    description:
      'Crunchyroll 1 mes y 12 meses',
    image: crunchyrroll,
  },
  {
    name: 'Youtube',
    description:
      'Cuentas de Youtube de 1 a 12 meses',
    image: youtube,
  },
  {
    name: 'Paramount',
    description:
      'Paramount 1 mes Original',
    image: paramount,
  },
  {
    name: 'Plex',
    description:
      'Plex 1 y 3 meses',
    image: plex,
  },
  {
    name: 'ECDF',
    description:
      'ECDF por Youtube y por navegador de 1 mes',
    image: ecdf,
  },
];


const giftcards = [
  {
    name: 'Gift Card PlayStation EEUU',
    description: 'USD 10, USD 25, USD 50, USD 75, USD 100, USD 150, USD 200, USD 250',
    image: pinPlayStation,
  },
  {
    name: 'Gift Card Razer Gold Global',
    description: 'USD 1, USD 2, USD 5, USD 10, USD 20, USD 50, USD 100, USD 200, USD 300, USD 400, USD 500',
    image: razerGold,
  },
  {
    name: 'Gift Card Apple itunes EEUU',
    description: 'USD 2, USD 3, USD 4, USD 5, USD 10, USD 15, USD 20, USD 25, USD 30, USD 50, USD 100, USD 200, USD 300, USD 400, USD 500',
    image: itunes,
  },
  {
    name: 'Gift Card Steam EEUU',
    description: '5 USD, 10 USD, 20 USD',
    image: pinSteam,
  },
  {
    name: 'Gift Card Xbox Live EEUU',
    description: '5 USD,  Gold 3 meses Membership, 10 USD, 15 USD, 20 USD, 25 USD',
    image: pinXbox,
  },
  {
    name: 'Gift Card Mobile Legends',
    description: '56 Diamonds, 278 Diamonds, 571 Diamonds, Legends Twilight Pass, 1167 Diamonds , 1783 Diamonds, 3005 Diamonds, 4770 Diamonds, 6012 Diamonds',
    image: pinLegendsBangBang,
  },
  {
    name: 'Gift Card PUBG Mobile',
    description: '60 UC, 300 + 25 UC, 600 + 60 UC , 1500 + 300 UC, 3000 + 850 UC, 8100 UC, 16200 UC, 24300 UC, 32400 UC, 40500 UC',
    image: pubgmobile,
  },
  {
    name: 'Gift Card Nintendo EEUU',
    description: 'USD 10, USD 20, USD 35, USD 50',
    image: nintento,
  },
  {
    name: 'Gift Card Free Fire Latinoamerica',
    description: '110 Diamonds,  231 Diamonds, 583 Diamonds, 1188 Diamonds, 2420 Diamonds',
    image: freeFire,
  },
  {
    name: 'Gift Card Amazon EEUU',
    description: 'CoUSD 10, USD 25, USD 35, USD 50, USD 100',
    image: amazon,
  },
  {
    name: 'Gift Card Netflix Colombia y EEUU',
    description: 'PIN 20.000 COP, PIN 40.000 COP, USD 30 EEUU',
    image: netflix,
  },
  {
    name: 'Otras Gift Card',
    description: 'Age of Legends, Arena Breakout, Black Clover, Crunchyroll, EAFC Mobile, Fortnite EEUU, Honor of Kings, imo, IMVU, Infinity Kingdom, Jawaker, Kammelna, League of Legends EUR,Merge Kingdom, Minecraft, Nexon Game Card, Parchis Club, R2Games, Starzplay, Tango, Roblox, eBay, UNDAWN y proximamente muchas más',
    image: pinFortnite,
  },
];


const videojuegos = [
  {
    name: 'Free Fire por ID en Linea',
    description: '110 Diamantes, 231 Diamantes,Tarjeta Semanal, 341 Diamantes, 451 Diamantes,583 Diamantes, 682 Diamantes, 792 Diamantes, 913 Diamantes, 1023 Diamantes,1188 Diamantes, Tarjeta Mensual, 1276 Diamantes, 1386 Diamantes, 1507 Diamantes, 1738 Diamantes, 2079 Diamantes, 2420 Diamantes, 3564 Diamantes, 4730 Diamantes, 6160 Diamantes ',
    image: freeFire,
  },
  {
    name: 'Recargas de Call of Duty',
    description:
      '80 COD, 420 COD, 500 COD, 880 COD, 960 COD, 1300 COD, 1760 COD, 2400 COD, 3280 COD, 5000 COD, 10800 COD',
    image: callOfDuty,
  },
  {
    name: 'Recargas de Roblox',
    description: '80 Robux, 400 Robux, 800 Robux, 1200 Robux, 1700 Robux, 2000 Robux, 4500 Robux, 10000 Robux',
    image: roblox,
  },
  {
    name: 'Recargas de Clash Royale por ID',
    description: '80+8 Gemas, 500+50 Gemas, Clash Royale Gold Pass, 1200+120 Gemas, Clash Royale Diamond Pass, 2500+250 Gemas, 6500+650 Gemas',
    image: clashRoyale,
  },
  {
    name: 'Recargas de Brawl Stars por ID',
    description: '30+3 Gemas, 80+8 Gemas, 170+17 Gemas,Brawl Stars Brawl Pass, 360+36 Gemas, 950+95 Gemas',
    image: brawstar,
  },
  {
    name: 'Recargas de Clash of Clans por ID',
    description: '80+8 Gemas, 500+50 Gemas, Clash of Clans Gold Pass, 1200+120 Gemas, 2500+250 Gemas, 6500+650 Gemas',
    image: clashOfClans,
  },
  {
    name: 'Otras recargas de Videojuegos',
    description: 'Apex Legends, Parchís, Hay Day, Fortnite V-Bucks Pavos, Razer Gold en linea, Saldo PayPal, Stumble Guys y proximamente muchos más',
    image: parchis,
  },
];


const licencias = [
  {
    name: 'Discord',
    description: 'Discord Nitro Classic Mensual, Discord Nitro Mensual, Discord Nitro Classic Anual, Discord Nitro Anual',
    image: discord,
  },
  {
    name: 'McAfee Antivirus',
    description: 'McAfee Antivirus 1 user, McAfee Internet Security 1 Device, McAfee Internet Security 3 Device, McAfee Total Protection 5 Device',
    image: mcafee,
  },
  {
    name: 'NordVPN',
    description: 'NordVPN Suscripción 1 Mes, NordVPN Suscripción 6 Meses, NordVPN Suscripción 12 Meses',
    image: nordvpn,
  },
  {
    name: 'Antivirus Eset',
    description: 'Eset Internet Security 1PC 1 Año Original, Eset Nod 32 1PC 1 Año Original',
    image: eset,
  },
];


const recargas_internacional = [
  {
    name: 'Cellvoz colombia',
    description: 'Recargas celulares de Colombia',
    image: cellvoz,
  },
  {
    name: 'Virgin colombia',
    description: 'Recargas celulares de Colombia',
    image: virgin,
  },
  {
    name: 'Une colombia',
    description: 'Recargas celulares de Colombia',
    image: une,
  },
  {
    name: 'Movil exito colombia',
    description: 'Recargas celulares de Colombia',
    image: movilExito,
  },
  {
    name: 'Flash mobile colombia',
    description: 'Recargas celulares de Colombia',
    image: flashMobile,
  },
  {
    name: 'Digitel venezuela',
    description: 'Recargas celulares de Venezuela',
    image: digitel,
  },
  {
    name: 'Cubacell cuba',
    description: 'Recargas celulares de Cuba',
    image: cubacell,
  },
  {
    name: 'Luz cuba',
    description: 'Disponible pago de luz para cuba.',
    image: luzCuba,
  },

];




//---------Para boton de siguiente y atras de carrusel pagina principal ------------------------------------------------//

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="absolute top-1/2 right-0 z-10 bg-transparent hover:bg-yellow-600 transform -translate-y-1/2 cursor-pointer"
      style={{ right: 10 }}
      onClick={onClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-8 h-8 text-yellow-600 hover:text-white">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
      </svg>
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="absolute top-1/2 left-0 z-10 bg-transparent hover:bg-yellow-600 transform -translate-y-1/2 cursor-pointer"
      style={{ left: 10 }}
      onClick={onClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-8 h-8 text-yellow-600 hover:text-white">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
      </svg>
    </div>
  );
};

//---------Para boton de siguiente y atras de carrusel pagina principal ------------------------------------------------//


export const LandingPage = () => {
  //---------Para carrusel pagina Inicio ----------------------------------------------------//
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Para que el carrusel pase automáticamente
    autoplaySpeed: 2500, // Tiempo en milisegundos para cambiar de imagen
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    arrows: true,
  };

  const [isNavSticky, setNavSticky] = useState(false);

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    setNavSticky(window.scrollY > 0);
    setIsVisible(window.scrollY > 200);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  //---------Para carrusel pagina Inicio ----------------------------------------------------//

  
  return (
    <div className="bg-white relative" >
      <Helmet>
        <title>{Titles.LANDING}</title>
      </Helmet>
      <div className="relative overflow-hidden"> 
        {/* Deja el menu Fijo mientras se navega */}
        <div className={`w-full top-0 left-0 z-50 ${isNavSticky ? 'fixed' : 'fixed'}`}> 
        {/* Superpone iniciar sesion encima de slider en celulares */}
        <Popover as="header" className={`${isNavSticky ? 'bg-orange-700 bg-opacity-100' : 'bg-black bg-opacity-25'}`}> 
            {({ open }) => (
            <>
            {/* Cambiar color menu superior y tamaño */}
              <div className="pt-0 bg-blue-600">
                <nav
                  className="relative flex items-center justify-between px-4 mx-auto max-w-7xl sm:px-6"
                  aria-label="Global"
                >
                  <div className="flex items-center flex-1">
                    <div className="flex items-center justify-between w-full md:w-auto">
                      <Link to="/">
                        <span className="sr-only">Gamerecar</span>
                        <img
                          className="hidden w-auto h-24 md:block"
                          src={logo}
                          alt="Gamerecar"
                        />
                      </Link>
                      <div className="flex items-center -mr-2 md:hidden">
                        <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-gray-900 rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                          <span className="sr-only">Abrir menu principal</span>
                          <MenuIcon className="w-6 h-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="hidden space-x-8 md:flex md:ml-10">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="text-base font-medium text-white hover:text-gray-300"
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                  <div className="hidden md:flex md:items-center md:space-x-6">
                    <Link
                        className="px-4 py-2 font-bold text-white bg-red-600 border-b-4 border-blue-600 rounded hover:bg-blue-800 hover:border-blue-800 hover:text-white"
                        to="/login"
                      >
                        {Labels.LOG_IN}
                      </Link>
                    <Link
                      className="px-4 py-2 font-bold text-white bg-red-800 border-b-4 border-blue-800 rounded hover:bg-blue-800 hover:border-blue-800 hover:text-white"
                      to="/register-new-user"
                    >
                      {Labels.REGISTER_NEW_USER_LANDING}
                    </Link>
                  </div>
                </nav>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  static
                  className="absolute inset-x-0 top-0 p-2 transition transform origin-top md:hidden"
                >
                  <div className="overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5">
                    <div className="flex items-center justify-between px-5 pt-4">
                      <div>
                        <img
                          className="w-auto h-24"
                          src={logo}
                          alt="Gamerecar"
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
                          <span className="sr-only">Close menu</span>
                          <XIcon className="w-6 h-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="pt-5 pb-6">
                      <div className="px-2 space-y-1">
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="block px-3 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-50"
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                      <div className="px-5 mt-6">
                        <p className="text-base font-medium text-center text-gray-500">
                          Ya tienes una cuenta?{' '}
                          <Link
                            className="text-gray-900 hover:underline"
                            to="/login"
                          >
                            {Labels.LOG_IN}
                          </Link>
                        </p>
                        <p className="text-base font-medium text-center text-gray-500">
                          Quieres crear una cuenta?{' '}
                          <Link
                            className="text-gray-900 hover:underline"
                            to="/register-new-user"
                          >
                            {Labels.REGISTER_NEW_USER_LANDING}
                          </Link>
                        </p>
                        
                        
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>


      <main>
        
      <div className="pt-10 bg-blue-900 sm:pt-16 lg:pt-20 lg:pb-14 lg:overflow-hidden">  
      <div className="relative w-full h-full">
          <Slider {...sliderSettings} >
                    <div >
                      <img src={slider1} alt="Imagen 1" className="object-cover w-full h-full" />
                    </div>
                    <div >
                      <img src={slider2} alt="Imagen 2" className="object-cover w-full h-full" />
                    </div>
                    <div >
                      <img src={slider3} alt="Imagen 3" className="object-cover w-full h-full" />
                    </div>
                    <div >
                      <img src={slider4} alt="Imagen 4" className="object-cover w-full h-full" />
                    </div>
                    <div >
                      <img src={slider5} alt="Imagen 5" className="object-cover w-full h-full" />
                    </div>
                    <div >
                      <img src={slider6} alt="Imagen 6" className="object-cover w-full h-full" />
                    </div>
          </Slider>
        </div>
      </div>
  

          <a href="https://wa.me/+593983468101"
            className="fixed bottom-5 left-5 z-50"
            target="_blank"
            rel="noopener noreferrer">
            <img src={ws}
              alt="WhatsApp"
              className="w-12 h-auto" />
          </a>

          <div className="back-to-top">
            {isVisible && (
              <div className="fixed bottom-5 right-5 z-50 cursor-pointer" onClick={scrollToTop}>
                <FaAngleUp className="back-to-top-icon bg-red-600 w-8 h-8 text-white rounded-full transition-opacity duration-300" />
              </div>)}
          </div>


          {/* Se cambia color que es gamerecar y texto */}
          <div id="que_es_gamerecar" className="pt-10 bg-blue-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
            <div id="start" className="mx-auto max-w-7xl lg:px-8">
              <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                <div className="max-w-md px-4 mx-auto sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                  <div className="lg:py-24">                  
                    <h1 className="mt-4 text-4xl font-extrabold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">              
                      <span className="block text-transparent bg-clip-text bg-gradient-to-r from-teal-200 to-cyan-400">
                      ¿Qué es GAMERECAR?
                      </span>
                    </h1>
                    <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    Somos una empresa ecuatoriana con más de 5 años de experiencia en el mercado, 
                    dedicada a impulsar el crecimiento de negocios y emprendimientos. 
                    Nuestra plataforma ofrece una amplia gama de servicios, incluyendo recargas de celulares, 
                    pagos de servicios básicos, recargas internacionales, tarjetas de regalo, 
                    cuentas de streaming,recargas de videojuegos y licencias. 
                    Con GAMERECAR, generar ingresos adicionales es fácil y rápido. 
                    ¡Únete a nosotros y transforma tu negocio hoy mismo!
                    </p>

                  <h1 className="mt-4 text-4xl font-extrabold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-5xl">
                    <span className="block text-transparent bg-clip-text bg-gradient-to-r from-teal-200 to-cyan-400">
                    Una plataforma digital completa y fácil de usar para vender Gifts Cards, Videojuegos, Recargas Celulares,
                     Streaming, Licencias, Servicios Básicos.
                    </span>
                  </h1>
                  <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  La plataforma GAMERECAR está diseñada para ser intuitiva y accesible desde cualquier dispositivo, 
                  ya sea un celular, tablet o computadora. Puedes utilizarla desde cualquier lugar y en cualquier momento. 
                  Además, contamos con soporte personalizado disponible las 24 horas del día, 
                  incluyendo días feriados y fines de semana, para garantizar tu satisfacción y confianza. 
                  Con GAMERECAR, tendrás una herramienta confiable y eficiente para hacer crecer tu negocio 
                  y ganar la confianza de tus clientes.
                    </p>

                  </div>
                </div>
                <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                  <div className="max-w-md px-4 mx-auto sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">                
                    <img
                      className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                      src="https://tailwindui.com/img/component-images/cloud-illustration-teal-cyan.svg"
                      alt=""
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Recargas celulares*/}

          <div id="productos" className="relative py-16 bg-white sm:py-24 lg:py-32">
            <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 className="text-2xl font-semibold tracking-wider uppercase text-cyan-600">
                RECARGAS CELULARES ECUADOR
              </h2>
              <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Realiza en la plataforma recargas de saldo y paquetes de todas las operadoras
              </p>
              <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
              Obtienes una comisión por cada recarga realizada, la cual se acredita de forma inmediata al momento de la transacción
              </p>
              <div className="mt-12">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {recargas.map((feature) => (
                    <div key={feature.name} className="pt-6">
                      <div className="px-6 pb-8 rounded-lg flow-root bg-gray-50">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center p-3 bg-white shadow-lg rounded-md">
                              <img
                                src={feature.image}
                                className="w-20 h-20 text-white"
                                aria-hidden="true"
                                alt={feature.name}
                              />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                            {feature.name}
                          </h3>
                          <p className="mt-5 text-base text-gray-500">
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>


          {/* Servicios basicos*/}
          <div id="productos" className="relative py-16 bg-white sm:py-24 lg:py-32">
            <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 className="text-2xl font-semibold tracking-wider uppercase text-cyan-600">
                PAGO DE SERVICIOS BÁSICOS ECUADOR
              </h2>
              <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Realiza los pagos de todos los servicios básicos de manera fácil y sencilla. Se entrega un recibo imprimible como respaldo del pago
              para ser entregado al cliente
              </p>
              <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
              Obtienes una comisión por cada pago realizado, la cual se acredita de forma inmediata al momento de la transacción.
              Tenemos mas de 500 servicios básicos disponibles para hacer pagos
              </p>
              <div className="mt-12">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {serviciosbasicos.map((feature) => (
                    <div key={feature.name} className="pt-6">
                      <div className="px-6 pb-8 rounded-lg flow-root bg-gray-50">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center p-3 bg-white shadow-lg rounded-md">
                              <img
                                src={feature.image}
                                className="w-20 h-20 text-white"
                                aria-hidden="true"
                                alt={feature.name}
                              />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                            {feature.name}
                          </h3>
                          <p className="mt-5 text-base text-gray-500">
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>


          {/* Entretenimiento*/}
          <div id="productos" className="relative py-16 bg-white sm:py-24 lg:py-32">
            <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 className="text-2xl font-semibold tracking-wider uppercase text-cyan-600">
                CUENTAS STREAMING
              </h2>
              <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Cuentas Streaming originales con garantía y soporte los 7 días de la semana
              </p>
              <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
              Obtienes una comisión por cada cuenta vendida al cliente final al momento de realizar la transacción
              </p>
              <div className="mt-12">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {entretenimiento.map((feature) => (
                    <div key={feature.name} className="pt-6">
                      <div className="px-6 pb-8 rounded-lg flow-root bg-gray-50">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center p-3 bg-white shadow-lg rounded-md">
                              <img
                                src={feature.image}
                                className="w-20 h-20 text-white"
                                aria-hidden="true"
                                alt={feature.name}
                              />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                            {feature.name}
                          </h3>
                          <p className="mt-5 text-base text-gray-500">
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>



          {/* Gift Cards*/}
          <div id="productos" className="relative py-16 bg-white sm:py-24 lg:py-32">
            <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 className="text-2xl font-semibold tracking-wider uppercase text-cyan-600">
                GIFTS CARDS
              </h2>
              <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Gift Cards entregadas de forma automática tenemos mas de 50 tipos de tarjetas de regalo
              </p>
              <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
              Obtienes una comisión por cada gift card al momento de realizar la venta
              </p>
              <div className="mt-12">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {giftcards.map((feature) => (
                    <div key={feature.name} className="pt-6">
                      <div className="px-6 pb-8 rounded-lg flow-root bg-gray-50">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center p-3 bg-white shadow-lg rounded-md">
                              <img
                                src={feature.image}
                                className="w-20 h-20 text-white"
                                aria-hidden="true"
                                alt={feature.name}
                              />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                            {feature.name}
                          </h3>
                          <p className="mt-5 text-base text-gray-500">
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Videojuegos*/}
          <div id="productos" className="relative py-16 bg-white sm:py-24 lg:py-32">
            <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 className="text-2xl font-semibold tracking-wider uppercase text-cyan-600">
                RECARGAS DE VIDEOJUEGOS
              </h2>
              <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Recargas de videojuegos por ID o por cuenta si no esta en el listado lo revisamos y lo hacemos
              </p>
              <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
              Obtienes una muy buena comisión por cada recarga de videojuegos al momento de realizar la venta
              </p>
              <div className="mt-12">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {videojuegos.map((feature) => (
                    <div key={feature.name} className="pt-6">
                      <div className="px-6 pb-8 rounded-lg flow-root bg-gray-50">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center p-3 bg-white shadow-lg rounded-md">
                              <img
                                src={feature.image}
                                className="w-20 h-20 text-white"
                                aria-hidden="true"
                                alt={feature.name}
                              />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                            {feature.name}
                          </h3>
                          <p className="mt-5 text-base text-gray-500">
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>



          {/* Licencias*/}
          <div id="productos" className="relative py-16 bg-white sm:py-24 lg:py-32">
            <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 className="text-2xl font-semibold tracking-wider uppercase text-cyan-600">
                LICENCIAS DE SOFTWARE PROGRAMAS
              </h2>
              <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Licencias originales de software y programas 
              </p>
              <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
              Obtienes una comisión por cada licencia vendida
              </p>
              <div className="mt-12">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {licencias.map((feature) => (
                    <div key={feature.name} className="pt-6">
                      <div className="px-6 pb-8 rounded-lg flow-root bg-gray-50">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center p-3 bg-white shadow-lg rounded-md">
                              <img
                                src={feature.image}
                                className="w-20 h-20 text-white"
                                aria-hidden="true"
                                alt={feature.name}
                              />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                            {feature.name}
                          </h3>
                          <p className="mt-5 text-base text-gray-500">
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>



          {/* Recargas internacionales*/}
          <div id="productos" className="relative py-16 bg-white sm:py-24 lg:py-32">
            <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 className="text-2xl font-semibold tracking-wider uppercase text-cyan-600">
                RECARGAS INTERNACIONALES
              </h2>
              <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Realiza en la plataforma recargas de saldo y pagos de servicios básicos internacionales
              </p>
              <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
              Obtienes una comisión por cada venta realizada, la cual se acredita de forma inmediata al momento de la transacción
              </p>
              <div className="mt-12">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {recargas_internacional.map((feature) => (
                    <div key={feature.name} className="pt-6">
                      <div className="px-6 pb-8 rounded-lg flow-root bg-gray-50">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center p-3 bg-white shadow-lg rounded-md">
                              <img
                                src={feature.image}
                                className="w-20 h-20 text-white"
                                aria-hidden="true"
                                alt={feature.name}
                              />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                            {feature.name}
                          </h3>
                          <p className="mt-5 text-base text-gray-500">
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

  

          <div id="afiliacion" className="relative pt-16 bg-gray-50 sm:pt-24 lg:pt-32">
            <div className="max-w-md px-4 mx-auto text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
              <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                <div>
                  <h2 className="text-3xl font-extrabold text-blue-900">
                    AFILIACIÓN GRATIS
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                  Estás a un paso de unirte a la creciente red de la empresa Gamerecar 
                  y acceder a una nueva oportunidad de negocio para generar ingresos rentables. {' '}

                  Luego nuestro equipo de soporte verifica tu información y te activamos la cuenta.
                                     
                    <a
                    >
                      <Link
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                          to="/register-new-user"
                    >
                        {Labels.REGISTER_NEW_USER_LANDING}
                      </Link>

                    </a>{' '}
                    
                  </p>
                </div>

                <div>
                  <h2 className="text-3xl font-extrabold text-blue-900">
                    ATRAE MAS CLIENTES A TU TIENDA O NEGOCIO
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                  Atrae nuevos clientes ofreciendo recargas de videojuegos, servicios de streaming, 
                  gift cards, recargas celulares y pagos de servicios básicos. 
                  Tus clientes podrán pagar todos sus servicios en un solo lugar: tu tienda.                  
                  </p>
                </div>

                <div>
                  <h2 className="text-3xl font-extrabold text-blue-900">
                    SOMOS UN GRAN ALIADO
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                  Resuelve los problemas de tus clientes ofreciéndoles todo lo que necesitan pagar y comprar en un solo lugar, 
                  justo a la vuelta de la esquina. Tu negocio.               
                  </p>
                </div>
              </div>
            </div>
          </div>


          <div className="relative pt-16 bg-gray-50 sm:pt-24 lg:pt-32">
            <div className="max-w-md px-4 mx-auto text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
                  <dl className="space-y-12">
                    <div>
                      <dt className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        ¿Cómo me puedo registrar?
                      </dt>
                      <dd className="mt-3 text-base text-yellow-700 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl list-decimal pl-5">
                      ¿Tienes un negocio y deseas vender nuestros productos para obtener un ingreso adicional? 
                      Sigue estos sencillos pasos para empezar a vender con nuestra plataforma. 
                      El registro es gratuito y muy fácil:

                      <ul className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl list-decimal pl-5">

                      <li >Regístrate gratuitamente usando nuestro enlace de registro.</li>
                      <li>Nuestro equipo de soporte verificará tus datos y activará tu cuenta.</li>
                      <li>Inicia sesión con tu usuario y contraseña.</li>
                      <li>Realiza una carga de saldo mediante transferencia o depósito a las cuentas corrientes de la empresa
                        tenemos Banco Pichincha, Produbanco, Pacífico, Bolivariano, Guayaquil.</li>
                      <li>¡Listo! Ahora puedes vender todos nuestros productos con el saldo disponible en tu cuenta.</li>                      
                      </ul>
                        <ul className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                          <li>
                            Si deseas más información y soporte envíanos un{' '}
                            <a
                              href="https://wa.me/+593983468101"
                              className="font-medium text-indigo-600 hover:text-indigo-500"
                              target="_blank"
                            >
                              WhatsApp,
                            </a>{' '}
                            indicándonos que deseas registrarte y te ayudamos
                            con los requisitos y pasos a seguir.
                          </li>
                        </ul>
                      </dd>
                    </div>
                  </dl>
                  <dl className="mt-8 space-y-12">
                  </dl>
            </div>
          </div>

          <div id='comisiones' className="relative pt-16 bg-gray-50 sm:pt-24 lg:pt-32">
            <div className="max-w-md px-4 mx-auto text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
              <div>
                <h2 className="text-2xl font-semibold tracking-wider uppercase text-cyan-600">
                  COMISIONES
                </h2>
                <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Ganancias inmediatas al momento de cobrar a tu cliente.
                </p>
                <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
                Nuestro modelo de negocio se basa en ofrecer GANANCIAS INMEDIATAS. Esto significa que obtienes tu comisión 
                inmediatamente después de cada venta realizada. El costo de cada producto se descuenta de tu saldo, 
                dejando como ganancia la diferencia que cobras al cliente.
                </p>
                <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose"> Ejemplo:
                Vendes 1 Perfil de Netflix 30 días
                Valor descontado de tu saldo: $ 3.77
                Valor a cobrar al cliente: $ 5.00
                Ganancia inmediata: $ 1.23 </p>
              </div>
              <div className="mt-12">
                <img
                  className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
                  src={cover}
                  alt="cover"
                />
              </div>
            </div>
          </div>


          <div >
            <br />
            <br />
          </div>

          <div id='contactanos' className="relative bg-gray-900">
            <div className="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
              <img
                className="object-cover w-full h-full"
                src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&sat=-100"
                alt=""
              />
              <div
                aria-hidden="true"
                className="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-600"
                style={{ mixBlendMode: 'multiply' }}
              />
            </div>
            <div className="relative max-w-md px-4 py-12 mx-auto sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
              <div className="md:ml-auto md:w-1/2 md:pl-10">
                <h2 className="text-base font-semibold tracking-wider text-gray-300 uppercase">
                  Soporte personalizado
                </h2>
                <p className="mt-2 text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
                  Estamos aquí para ayudarte
                </p>
                <p className="mt-3 text-lg text-gray-300">
                  Si tienes alguna duda con alguno de nuestros productos, o
                  necesitas ayuda personalizada, contáctanos sin ninguna duda.
                  Tenemos un equipo de soporte de varios operadores que atienden los
                  7 días de la semana incluido feriados los 365 días del año.
                
                </p>
                <div className="mt-8">
                  <div className="inline-flex shadow rounded-md">
                    <a
                      href="https://wa.me/+593983468101"
                      className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-gray-900 bg-white border border-transparent rounded-md hover:bg-gray-50"
                      target="_blank"
                    >
                      WhatsApp
                      <img src={ws} alt="ws" className="w-6 h-6 ml-2" />
                    </a>                 
                  </div>

                  <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      ¿Tienes alguna propuesta comercial o deseas más información sobre nuestra empresa?
                      No dudes en enviarnos un correo electrónico a {' '}
                      <a className="font-medium text-indigo-600 hover:text-indigo-500"
                              target="_blank" href="mailto:gerencia@gamerecar.com">gerencia@gamerecar.com </a>.
                      Estaremos encantados de atender tus consultas y propuestas.
                    </p>

                </div>
              </div>
            </div>
          </div>


          <div id='api' className="relative pt-16 bg-gray-50 sm:pt-24 lg:pt-32">
            <div className="max-w-md px-4 mx-auto text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
              <div>
                <h2 className="text-2xl font-semibold tracking-wider uppercase text-cyan-600">
                  API REST GAMERECAR
                </h2>
                <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Quieres integrar a tu sistema, app, o punto de venta Recargas Electrónicas, Pagos de Servicios Básicos
                y Gift Cards de Videojuegos
                </p>
                <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
                Si eres Desarollador o dueño de negocios y cuentas con tu propio sistema de punto de venta o app te ofrecemos
                la posibilidad de integrar via API Rest nuestros productos de recargas celulares de todas las operadoras de Ecuador asi como recargas 
                Internacionales, más de 500 opciones de pagos de servicios básicos de Ecuador y más de 50 tipos de
                Gift Cards Globales en línea.
                </p>
                <p className="mx-auto mt-5 mb-8 text-xl text-gray-500 max-w-prose"> 
                Disponemos de ambiente de desarollo y ambiente de producción para la integración API Rest de las siguientes categorias
                </p>
                <h2 className="text-2xl font-semibold tracking-wider uppercase text-cyan-600">
                Recargas Directas: Topup
                </h2>
                <p className="mx-auto mt-5 mb-8 text-xl text-gray-500 max-w-prose"> 
                 Realiza recargas en línea via API de Free Fire, Mobile Legends, Razer Gold
                 </p>
                 <h2 className="text-2xl font-semibold tracking-wider uppercase text-cyan-600">
                 Gift Cards Tarjetas de regalo
                </h2>
                <p className="mx-auto mt-5 mb-8 text-xl text-gray-500 max-w-prose"> 
                 Más de 50 opciones en linea de Gift Cards de Videojuegos incluido itunes, PlayStation, Nintendo, Xbox
                 Steam, Amazon, Razer Gold, Free Fire, Mobile Legends.
                 </p>
                 <h2 className="text-2xl font-semibold tracking-wider uppercase text-cyan-600">
                 Recargas celulares:
                </h2>
                <p className="mx-auto mt-5 mb-8 text-xl text-gray-500 max-w-prose">
                 Recargas de celulares de todas las operadoras del país: 
                Claro, Movistar, Tuenti, Akimovil, CNT, Directv, Maxiplus y tambien internacionales : Venezuela, Cuba, Colombia. 
                </p>
                <h2 className="text-2xl font-semibold tracking-wider uppercase text-cyan-600">
                Servicios Básicos:
                </h2>
                <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
                 Integra todos los pagos de servicios básicos de Ecuador, desde luz, agua, internet, SRI, Tránsito, 
                depositos a bancos y cooperativas , pagos de tarjetas, pronósticos deportivos.
                El API entrega más de 500 servicios que se pueden pagar a nivel nacional 
                </p>
              </div>

              <div className="mt-8">
                  <div className="inline-flex shadow rounded-md">
                    <a
                      href="https://wa.me/+593983468101"
                      className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-gray-900 bg-white border border-transparent rounded-md hover:bg-gray-50"
                      target="_blank"
                    >
                      WhatsApp
                      <img src={ws} alt="ws" className="w-6 h-6 ml-2" />
                    </a>                 
                  </div>

                  <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      ¿Tienes alguna propuesta comercial o deseas más información sobre el API?
                      No dudes en enviarnos un correo electrónico a {' '}
                      <a className="font-medium text-indigo-600 hover:text-indigo-500"
                              target="_blank" href="mailto:gerencia@gamerecar.com">gerencia@gamerecar.com </a>.
                      Te responderemos de forma inmediata.
                    </p>

                </div>

            </div>
          </div>



      </main>
        <FooterLogout navigation={navigation} />
      </div>
    </div>
  );
};
