import React, { useState } from 'react';
import { Titles } from '../../enums/titles.enum';
import { Helmet } from 'react-helmet-async';
import { Labels } from '../../enums/labels.enum';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { useMe } from '../../hooks/use-me';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlus } from '@fortawesome/free-solid-svg-icons';
import { DatePicker, Modal as AModal, Table, Tag, Tooltip } from 'antd';
import { ConsignmentStatus as ConsignmentStatusOriginal } from '../../__api__/globalTypes';
import { ConsignmentStatus } from '../../enums/spanish.enum';
import { Modal } from '../../components/modal';
import { CreateConsignment } from './create-consignment';
import moment from 'moment';
import {
  consignments,
  consignments_consignments_nodes_Consignment,
  consignmentsVariables,
} from '../../__api__/consignments';
import {
  SkeletonTable,
  SkeletonTableColumnsType,
} from '../../components/skeleton-table';
import { JSONDetail } from '../../components/jsonDetails';
import { InformationCircleIcon } from '@heroicons/react/outline';

const { RangePicker } = DatePicker;

const CONSIGNMENTS_QUERY = gql`
  query consignments($input: ConsignmentSearcherInput!) {
    consignments(input: $input) {
      meta {
        nodeCount
        nodesPerPage
        pageCount
        pageCurrent
      }
      nodes {
        __typename
        ... on Consignment {
          id
          voucherNumber
          bankAccount {
            accountNumber
            accountHolder
            bankName
          }
          amount
          transactionDate
          attachedURL
          status
          comment
        }
      }
    }
  }
`;

const DEFAULT_PAGE_SIZE = 10;

export const Consignments = () => {
  const [pageCurrent, setPageCurrent] = useState(1);
  const [nodesPerPage, setNodesPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);
  const [consignment, setconsignment] = useState<any>(null);
  const [status, setStatus] = useState<ConsignmentStatusOriginal[] | null>(
    null,
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalReviewVisible, setIsModalReviewVisible] = useState(false);

  const onChange = (values: any) => {
    if (!values) {
      setDateFrom(null);
      setDateTo(null);
      return;
    }
    const dateFrom = values[0].format();
    const dateTo = values[1].format();

    setDateFrom(dateFrom);
    setDateTo(dateTo);
  };
  const { data: user } = useMe();
  const { data, loading, refetch } = useQuery<
    consignments,
    consignmentsVariables
  >(CONSIGNMENTS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        pageCurrent,
        nodesPerPage,
        where: {
          customerId: user?.me.customer?.id,
          dateFrom,
          dateTo,
          status,
        },
      },
    },
  });

  const consignments = data?.consignments.nodes as
    | consignments_consignments_nodes_Consignment[]
    | null;

  const success = () => {
    AModal.success({
      content: 'Consignación creada satisfactoriamente',
    });
  };

  const columns = [
    {
      title: 'Comprobante',
      dataIndex: 'voucher',
      key: 'voucher',
    },
    {
      title: 'Monto consignado',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Cuenta bancaria',
      dataIndex: 'bankAccount',
      key: 'bankAccount',
    },
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Comentario',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const datasource = consignments?.map((item) => ({
    key: item.id,
    voucher: item.voucherNumber,
    amount: item.amount,
    bankAccount: item.bankAccount!=null ?  `${item.bankAccount.bankName} - ${item.bankAccount.accountNumber} - ${item.bankAccount.accountHolder}` : 'No aplica',
    date: item.transactionDate,
    action: (<div
      onClick={() => {
        setconsignment(item);
        setIsModalReviewVisible(true);
      }}
      className="flex-shrink-0 mr-2"
    >
      <Tooltip title={'ver detalle'}>
        <InformationCircleIcon
          className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
          aria-hidden="true"
        />
      </Tooltip>
    </div>),
    status: (
      <Tag
        color={
          item.status === 'ACCEPTED'
            ? 'geekblue'
            : item.status === 'PENDING'
            ? 'orange'
            : item.status === 'REJECTED'
            ? 'error'
            : 'cyan'
        }
      >
        {ConsignmentStatus[item.status]}
      </Tag>
    ),
    comment: item.comment,
  }));

  return (
    <div>
      <Modal
        title="Revisión de la consignación"
        visible={isModalReviewVisible}
        onOk={async () => {
          setIsModalReviewVisible(false);
          await refetch();
        }}
        onCancel={async () => {
          setIsModalReviewVisible(false);
          await refetch();
        }}
        child={
          <JSONDetail
            json={consignment}
            imageURL={consignment?.attachedURL}
            labels={{id:"ID",voucherNumber:"Comprobante",bankAccount:"Cuenta de Banco",amount:"monto",transactionDate:"Fechas de la transacción",status:"Estado"}}
          />
        }
      />
      <Modal
        title={Labels.CREATE_CONSIGNMENT}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        afterClose={async () => {
          setPageCurrent(1);
          setDateTo(null);
          setDateTo(null);
          await refetch();
        }}
        child={
          <CreateConsignment
            onOk={() => {
              setIsModalVisible(false);
              success();
            }}
          />
        }
      />
      <Helmet>
        <title>{Titles.CONSIGNMENTS}</title>
      </Helmet>
      <div className="py-6">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900 leading-6">
              {Labels.MENU_CONSIGNMENT}
            </h3>
            <button
              type="button"
              onClick={() => {
                setIsModalVisible(true);
              }}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent shadow-sm leading-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <div className="flex items-center justify-between">
                <span className="pr-2">{Labels.CREATE}</span>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </button>
          </div>
          <div className="mt-5 mb-5 md:mt-0 md:col-span-2">
            <form className="flex flex-col items-center w-full py-8 bg-white shadow sm:rounded-3xl sm:px-4">
              <div className="mb-4 mr-auto">
                {Labels.FILTERS}
                <FontAwesomeIcon icon={faFilter} className="ml-3 text-xl" />
              </div>
              <div className="max-w-xl grid grid-cols-1 gap-5">
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_DATES}</span>
                  </label>
                  <RangePicker
                    showTime={true}
                    placeholder={['Fecha desde', 'Fecha hasta']}
                    className="w-full rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={onChange}
                    disabledDate={(current) => moment().isBefore(current)}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_STATUS}</span>
                  </label>
                  <select
                    name="status"
                    onChange={(event) => {
                      const value = event.target.value;
                      setStatus(
                        value ? ([value] as ConsignmentStatusOriginal[]) : null,
                      );
                    }}
                    className="w-full select"
                  >
                    <option value="">Todos</option>
                    {Object.keys(ConsignmentStatusOriginal).map((state) => (
                      <option key={state} value={state}>
                        {ConsignmentStatus[state as any]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </form>
          </div>
          <SkeletonTable
            active={true}
            loading={loading}
            columns={columns as SkeletonTableColumnsType[]}
          >
            <Table
              dataSource={datasource}
              columns={columns}
              pagination={{
                defaultPageSize: DEFAULT_PAGE_SIZE,
                pageSize: nodesPerPage,
                current: pageCurrent,
                total: data?.consignments.meta.nodeCount,
                responsive: true,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '100'],
                onShowSizeChange: (current, size) => setNodesPerPage(size),
                onChange: (page) => setPageCurrent(page),
              }}
            />
          </SkeletonTable>
        </div>
      </div>
    </div>
  );
};
